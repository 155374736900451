import { Contract } from '@ethersproject/contracts'
import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'
import { IPancakeRouter02 } from 'config/abi/types/IPancakeRouter02'
import { CHAIN_ID } from 'config/constants/networks'
import { JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from 'sdk'
import { ROUTER_ADDRESS } from '../config/constants'
import { BASE_BSC_SCAN_URLS } from '../config'
import { TokenAddressMap } from '../state/lists/hooks'
import { simpleRpcProvider } from './providers'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainIdOverride?: number,
): string {
  const chainId = chainIdOverride || CHAIN_ID
  switch (type) {
    case 'transaction': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_BSC_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_BSC_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

export function getBscScanLinkForNft(collectionAddress: string, tokenId: string): string {
  const chainId = CHAIN_ID
  return `${BASE_BSC_SCAN_URLS[chainId]}/token/${collectionAddress}?a=${tokenId}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, signer?: Signer | Provider): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer ?? simpleRpcProvider)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string) {
  return getContract(
    ROUTER_ADDRESS[CHAIN_ID],
    IPancakeRouter02ABI,
    getProviderOrSigner(library, account),
  ) as IPancakeRouter02
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function formatTimestamp(timestamp:number): string{
  var date = new Date(timestamp * 1000);

// 使用Date对象的方法获取具体的日期和时间信息
  var year = date.getFullYear(); // 获取年份
  var month = date.getMonth() + 1; // 获取月份（注意：月份从0开始，所以要加1）
  var day = date.getDate(); // 获取日期
  var hours = date.getHours(); // 获取小时
  var minutes = date.getMinutes(); // 获取分钟
  var seconds = date.getSeconds(); // 获取秒数

// 格式化输出日期和时间
  var formattedDate = year + '-' + addLeadingZero(month) + '-' + addLeadingZero(day);
  var formattedTime = addLeadingZero(hours) + ':' + addLeadingZero(minutes) + ':' + addLeadingZero(seconds);
  var formattedDateTime = formattedDate + ' ' + formattedTime;
  return formattedDateTime;
}

function addLeadingZero(number) {
  return number < 10 ? '0' + number : number;
}

export function sliceString(str) {
  if (str.length <= 30) {
    return str;
  }
  return str.slice(0, 18) +'...' +str.slice(-15);
}

export function compareWithCurrentDate(dateString) {
  // 解析传入的日期字符串
  const inputDate = new Date(dateString);

  // 获取当前日期和时间
  const currentDate = new Date();

  // 比较传入的日期和当前日期
  return inputDate <= currentDate;
}
