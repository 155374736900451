import {ChainId, Token} from 'sdk'
import {serializeToken} from 'state/user/hooks/helpers'
import {CHAIN_ID} from './networks'
import {SerializedToken} from './types'

const {MAINNET, TESTNET, PG_TEST} = ChainId

interface TokenList {
    [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = defineTokens({
    busdt: new Token(
        MAINNET,
        '0x02F9Bebf5E54968D8Cc2562356C91ECDE135801B',
        18,
        'USDT',
        'Wrapped BNB',
        'https://www.binance.com/',
    ),
    usdtt: new Token(
        MAINNET,
        '0x3aE20f22A9F337Be0eb225763A41e3db0Bbe2768',
        18,
        'USDT-T',
        'USDT-T',
        '',
    ),
    oskdao: new Token(
        MAINNET,
        '0x1385Aa68AC960Abb0112aa5905FACE08EFe48053',
        18,
        'OSKDAO',
        'OSKDAO',
        '',
    ),
    wbnb: new Token(
        MAINNET,
        '0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
        18,
        'WPG',
        'Wrapped PG',
        'https://www.binance.com/',
    ),
    // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
    bnb: new Token(MAINNET, '0x0cF4071940782b640d0b595Cb17bDf3E90869d70', 18, 'PG', 'PG', 'https://www.binance.com/'),
    cake: new Token(
        MAINNET,
        '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        18,
        'CAKE',
        'PancakeSwap Token',
        'https://pancakeswap.finance/',
    ),
    busd: new Token(
        MAINNET,
        '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        18,
        'USDT',
        'USDT',
        'https://www.paxos.com/busd/',
    ),
    dai: new Token(
        MAINNET,
        '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        18,
        'DAI',
        'Dai Stablecoin',
        'https://www.makerdao.com/',
    ),
    usdt: new Token(
        MAINNET,
        '0x02F9Bebf5E54968D8Cc2562356C91ECDE135801B',
        18,
        'USDT',
        'USDT',
        'https://tether.to/',
    ),
    ust: new Token(
        MAINNET,
        '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
        18,
        'UST',
        'Wrapped UST Token',
        'https://mirror.finance/',
    ),
    eth: new Token(
        MAINNET,
        '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        18,
        'ETH',
        'Binance-Peg Ethereum Token',
        'https://ethereum.org/en/',
    ),
    usdc: new Token(
        MAINNET,
        '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        18,
        'USDC',
        'Binance-Peg USD Coin',
        'https://www.centre.io/usdc',
    ),
    test: new Token(
        MAINNET,
        '0x78190C23fdcfe272614D30ffB9cBEa8acf6b8f6B',
        18,
        'TEST',
        'TEST Token',
        'https://formation.fi/',
    ),
    pns: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xdDab45b5df2031eF4195091167a9Ff0830211094',
        18,
        'PNS',
        'PNS',
        'https://www.paxos.com/busd/',
    ),
    osktt: new Token(
        MAINNET,
        '0xB27c1870B5E3a2e61B34bd0D9e568ab409A16Da0',
        18,
        'OSKTT',
        'OSKTT',
        'https://www.paxos.com/busd/',
    ),
    citt: new Token(
        MAINNET,
        '0xDE2972d76607e946331Bdd61F19A3B8879C106Bc',
        18,
        'CITT',
        'CITT',
        'https://www.paxos.com/busd/',
    ),
    daausdt: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xf69640ae5bfbaaf92a77302d8a4e8f2a6c7f5dd0',
        18,
        'W3-LP(DAA/USDT)',
        'W3-LP(DAA/USDT)',
        'https://www.paxos.com/busd/',
    ),
    gsd: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xc47106a88f95668e20e159156a49ad3d4b3b1680',
        18,
        'GSD',
        'GSD',
        'https://www.paxos.com/busd/',
    ),
    g50: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0x88F7ED3dF2b52A87cBf0109D36f7fc991CCf6eB6',
        18,
        'G50',
        'G50',
        'https://www.paxos.com/busd/',
    ),
    mn: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0x5C440F22Bd660f18c480C66Ff6C91339C735d5d8',
        18,
        'MN',
        'MN',
        'https://www.paxos.com/busd/',
    ),
    w3: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xf88634C560a4AA2c6034DAd4Dc5e931A65cdF954',
        18,
        'W3',
        'W3',
        'https://www.paxos.com/busd/',
    ),
    w3k: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xCe721D6C56c628A5eBde4783abb9D70d2053AAb9',
        18,
        'W3K',
        'W3K',
        'https://www.paxos.com/busd/',
    ),
    w3b: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xe28489df1c10465934Ac9FDF755a6cDF86cC1559',
        18,
        'W3B',
        'W3B',
        'https://www.paxos.com/busd/',
    ),
    w3w: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0x5AbD5f6Bbe0B48E9179F6307eAea032d998B4a7e',
        18,
        'W3W',
        'W3W',
        'https://www.paxos.com/busd/',
    ),
    pnn: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0xB84AC451A43B9A81d753c9a9d1F4F9A1B9DB4BFb',
        18,
        'PNN',
        'PNN',
        'https://www.paxos.com/busd/',
    ),
    gss: new Token(
        MAINNET,
        // '0x6d722beF10fA1D05Bb4c0D031C437f44129cbC62',
        '0x8959c2caa51f7e7edef6fb1ac23ec15f21533f28',
        18,
        'GSS',
        'GSS',
        'https://www.paxos.com/busd/',
    ),
    wss: new Token(
        MAINNET,
        '0xEcEa526E10A705EcAAdbD5732F8EDF934bAF1242',
        18,
        'WSS',
        'WSS',
        'https://www.paxos.com/busd/',
    ),
    fog: new Token(
        MAINNET,
        '0x6D8A0b8F9A31648a241A161aB8A1A3938d500575',
        18,
        'FOG',
        'FOG',
        'https://www.paxos.com/busd/',
    ),
    pct: new Token(
        MAINNET,
        '0x7151f6D2D74FEBF56103Ee0e28B1863B38B52eE0',
        18,
        'PCT',
        'PCT',
        'https://www.paxos.com/busd/',
    ),
    iwo: new Token(
        MAINNET,
        '0xe720f2468216D6806F9A33642Fa7Df6327247F6d',
        18,
        'IWO',
        'IWO',
        'https://www.paxos.com/busd/',
    ),
    wpg: new Token(
        MAINNET,
        '0x0cF4071940782b640d0b595Cb17bDf3E90869d70',
        18,
        'WPG',
        'WPG',
        'https://www.paxos.com/busd/',
    ),
    pbk: new Token(
        MAINNET,
        '0x6B1c0D7761967d6605C9D905A4aD7A9c4382DAce',
        18,
        'PBK',
        'PBK',
        'https://www.paxos.com/busd/',
    ),
    mw: new Token(
        MAINNET,
        '0x0fcfC5DDde7A1f5C5C8bDF0Dc9D0E6109536B7D8',
        18,
        '4MW',
        '4MW',
        'https://www.paxos.com/busd/',
    ),
    wzz: new Token(
        MAINNET,
        '0xb56cf5a803697c78b1fd4aa3466aeae0c3503f2c',
        18,
        'WZZ',
        'WZZ',
        'https://www.paxos.com/busd/',
    ),
    tqloong: new Token(
        MAINNET,
        '0x00db5c4cefce123694e41039634da2f3e6ab352b',
        18,
        'TQLoong',
        'TQLoong',
        'https://www.paxos.com/busd/',
    ),
    tqloongnew: new Token(
        MAINNET,
        '0x941c789fef622e5fdfa834b859bc25610732137d',
        18,
        'TQLoong-NEW',
        'TQLoong-NEW',
        'https://www.paxos.com/busd/',
    ),
    wmn: new Token(
        MAINNET,
        '0xC3664758F3f4CDDDf6F8D7CfDc2A82CA93716871',
        18,
        'WMN',
        'WMN',
        'https://www.paxos.com/busd/',
    ),
    dora: new Token(
        MAINNET,
        '0x332E657DE4383F9d17C4F09d12Bed8F835141319',
        18,
        'Dora',
        'Dora',
        'https://www.paxos.com/busd/',
    ),
    osk: new Token(
        MAINNET,
        '0xC5db5aFee4C55DfAD5F2b8226C6ac882E6956a0A',
        18,
        'OSK',
        'OSK',
        'https://www.paxos.com/busd/',
    ),
    pgts: new Token(
        MAINNET,
        '0xed7ef983f0bD7492bF9715141E6Dd2018ABf4B03',
        18,
        'PGTS',
        'PGTS',
        'https://pancakeswap.finance/',
    ),
    pg: new Token(
        MAINNET,
        '0xfA82075A6d8F85Be9146e64e0F02BaA849F8E8Fb',
        18,
        'PG',
        'PG',
        'https://pancakeswap.finance/',
    ),
    w3farm: new Token(
        MAINNET,
        '0x304435136f97c3DE67be4B1109F12e25C8D058ED',
        18,
        'W3Farm',
        'W3Farm',
        'https://www.paxos.com/busd/',
    ),
    bscusd: new Token(
        MAINNET,
        '0x02F9Bebf5E54968D8Cc2562356C91ECDE135801B',
        18,
        'USDT',
        'USDT',
        'https://www.paxos.com/busd/',
    ),
    gna: new Token(
        MAINNET,
        '0x0Bc5772C76d274f2d387592b832dd3daE2AeEdF5',
        18,
        'GNA',
        'GNA',
        'https://www.binance.com/',
    ),
    kfc: new Token(
        MAINNET,
        '0xA9F8ab6DB6fE3D8247974dd7dD8a7e2712fDFECE',
        18,
        'KFC',
        'KFC',
        'https://www.binance.com/',
    ),
    xt: new Token(
        MAINNET,
        '0x85dA61E677A14b8eC14CD1a6DDd1B87bB0224690',
        18,
        'XT',
        'XT',
        'https://www.binance.com/',
    ),
    mmf: new Token(
        MAINNET,
        '0x64427e98B5403bbE8A95F12B935d4275d2802B26',
        18,
        'MMF',
        'MMF',
        'https://www.binance.com/',
    ),
    dsn: new Token(
        MAINNET,
        '0x4D4373d3b27468249626769718C2C99C80040fC2',
        18,
        'DSN',
        'DSNToken',
        'https://www.binance.com/',
    ),
    osktop: new Token(
        MAINNET,
        '0x8b022d7798C5d5fc4A448BE80E76209632998c6d',
        18,
        'OSK TOP',
        'OSK TOP',
        'https://www.binance.com/',
    ),
    tor: new Token(
        MAINNET,
        '0xa1fE3DDBe189D804e51C29772d222ac1f155E58e',
        18,
        'TorDao',
        'TorDao',
        'https://www.binance.com/',
    ),
    rrb: new Token(
        MAINNET,
        '0x4161557153cf56b10836B3f76F9b82561F23Cb0C',
        18,
        'RRB',
        'RRB',
        'https://www.binance.com/',
    ),
    qj: new Token(
        MAINNET,
        '0xfB374248A8EaDFAb6714f8063AA13d29B92ad927',
        9,
        'QJ',
        'QJ',
        'https://www.binance.com/',
    ),
    spt: new Token(
        MAINNET,
        '0x772C2CE3e0ebbEDdc29bE282c42E49D3A43BA1e2',
        6,
        'SPT',
        'Sparta',
        'https://www.binance.com/',
    ),

    ho: new Token(
        MAINNET,
        '0x41515885251e724233c6cA94530d6dcf3A20dEc7',
        18,
        'HO',
        'Binance-Peg PEGO-HO',
        'https://www.binance.com/',
    ),
    hbg: new Token(
        MAINNET,
        '0xe64Fd924909D2a441a85d82b633A4CC3c86E0f20',
        18,
        'HBG',
        'HBG',
        'https://www.binance.com/',
    ),
    hd: new Token(
        MAINNET,
        '0x2894b04BeF221ED896ef1e741EB641825d5D3462',
        18,
        'HD',
        'HD',
        'https://www.binance.com/',
    ),
    hos: new Token(
        MAINNET,
        '0xa0949F33FA921ca2571d469A1a4b08649838D34C',
        18,
        'HOS',
        'HOS',
        'https://www.binance.com/',
    ),
    igt: new Token(
        MAINNET,
        '0x4340499A4BD2869630393B7E4709E4f4581A9793',
        18,
        'IGT',
        'IGT',
        'https://www.binance.com/',
    ),
    st2: new Token(
        MAINNET,
        '0x95DfffA3B8f15F2697813D3dD46638A764Be4b7D',
        18,
        'ST2',
        'ST2',
        'https://www.binance.com/',
    ),
} as const)

export const testnetTokens = defineTokens({
    wbnb: new Token(
        TESTNET,
        '0x946d85034099c2e5b446f3b73fF042b9EBbCDFB8',
        18,
        'WBNB',
        'Wrapped BNB',
        'https://www.binance.com/',
    ),
    cake: new Token(
        TESTNET,
        '0x247633aB595FdCD61fdCe3B97B32c478BE4ef965',
        18,
        'CAKE',
        'PancakeSwap Token',
        'https://pancakeswap.finance/',
    ),
    syrup: new Token(
        TESTNET,
        '0xDaFD855319B9D840bCF80768B424f3CdD3332aB6',
        18,
        'SYRUP',
        'SyrupBar Token',
        'https://pancakeswap.finance/',
    ),
    bake: new Token(
        TESTNET,
        '0x855AbFA17668c79F74a7943e417F15fecBaa45eF',
        18,
        'BAKE',
        'Bakeryswap Token',
        'https://www.bakeryswap.org/',
    ),
    pgtS: new Token(
        TESTNET,
        '0xed7ef983f0bD7492bF9715141E6Dd2018ABf4B03',
        18,
        'PGTS',
        'PGTS',
        'https://pancakeswap.finance/',
    ),
    busd: new Token(
        TESTNET,
        '0x36EdFf132DA9BF912b430332cE48761DDFd37441',
        18,
        'USDT',
        'USDT',
        'https://www.paxos.com/busd/',
    ),
    usdt: new Token(
        TESTNET,
        '0x158e05189e80136381Ad3475d3Ba2F04Db202015',
        18,
        'USDT',
        'USDT',
        'https://pancakeswap.finance/',
    ),
    bot: new Token(
        TESTNET,
        '0xD49D2250beeACBdFa218fd158C3742B464Bfc551',
        18,
        'BORT',
        'Bit Store',
        'https://www.paxos.com/busd/',
    )
} as const)



const tokens = () => {
    const chainId = CHAIN_ID

    // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
    // if (chainId === '102000') return pgTestTokens
    if (parseInt(chainId, 10) === ChainId.TESTNET) {
        // return testnetTokens
        return Object.keys(mainnetTokens).reduce((accum, key) => {
            return {...accum, [key]: testnetTokens[key] || mainnetTokens[key]}
        }, {} as typeof testnetTokens & typeof mainnetTokens)
    } else {
        return mainnetTokens
    }
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
    const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
        return {...accum, [key]: serializeToken(unserializedTokens[key])}
    }, {} as SerializedTokenList)

    return serializedTokens
}

export default unserializedTokens
