import {Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Skeleton, Text} from 'uikit'
import {useWeb3React} from '@web3-react/core'
import {FetchStatus} from 'config/constants/types'
import {useTranslation} from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import {useGetBnbBalance} from 'hooks/useTokenBalance'

import {getBscScanLink} from 'utils'
import {formatBigNumber, getFullDisplayBalance} from 'utils/formatBalance'
import CopyAddress from './CopyAddress'
import {BIG_ZERO} from 'utils/bigNumber'

interface WalletInfoProps {
    hasLowBnbBalance: boolean
    onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({hasLowBnbBalance, onDismiss}) => {
    const {t} = useTranslation()
    const {account} = useWeb3React()
    const {balance, fetchStatus} = useGetBnbBalance()
    // const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)
    const cakeBalance = BIG_ZERO
    const cakeFetchStatus = FetchStatus.Fetched
    const {logout} = useAuth()

    const handleLogout = () => {
        onDismiss?.()
        logout()
    }

    return (
        <>
            <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
                {t('Your Address')}
            </Text>
            <CopyAddress account={account} mb="24px"/>
            {hasLowBnbBalance && (
                <Message variant="warning" mb="24px">
                    <Box>
                        <Text fontWeight="bold">{t('PG Balance Low')}</Text>
                        <Text as="p">{t('You need PG for transaction fees.')}</Text>
                    </Box>
                </Message>
            )}
            <Flex alignItems="center" justifyContent="space-between">
                <Text color="textSubtle">{t('PG Balance')}</Text>
                {fetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px"/>
                ) : (
                    <Text>{formatBigNumber(balance, 6)}</Text>
                )}
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mb="24px">
                <Text color="textSubtle">{t('W3 Balance')}</Text>
                {cakeFetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px"/>
                ) : (
                    <Text>{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
                )}
            </Flex>
            <Flex alignItems="center" justifyContent="end" mb="24px">
                <LinkExternal href={getBscScanLink(account, 'address')}>{t('View on PGScan')}</LinkExternal>
            </Flex>
            <Button variant="secondary" width="100%" onClick={handleLogout}>
                {t('Disconnect Wallet')}
            </Button>
        </>
    )
}

export default WalletInfo
