import { WrappedTokenInfo } from 'state/lists/hooks'

export const IWOaddress = "0x4D717D0F12E1f2738A4A4C493CB40773940979A3"
// export const IWOaddress = "0xE62F9aEab5605D270458457bE1713927B7Af886d"

export const USDT = new WrappedTokenInfo(
	{
		name: 'USDT',
		symbol: 'USDT',
		address: '0x02F9Bebf5E54968D8Cc2562356C91ECDE135801B',
		chainId: 56,
		decimals: 18,
		logoURI: '',
	},
	[],
)

export const W3 = new WrappedTokenInfo(
	{
		name: 'W3',
		symbol: 'W3',
		address: '0x7d6801E8CF716e6a06914b7106578F3beb40e51d',
		chainId: 56,
		decimals: 18,
		logoURI: '',
	},
	[],
)