import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  MAINNET = 20201022,
  TESTNET = 102000,
  PG_TEST = 102000,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export const FACTORY_ADDRESS_MAP = {
  [ChainId.MAINNET]: '0x94274b1Ed6E1BDe55fa631f502Aa18512Aa3007b',
  [ChainId.TESTNET]: '0xA376D173B37393e0085c098b07ee0b9B5CF81bbD',
  [ChainId.PG_TEST]: '0xa82d24D880337BD0f3772559761B3a646449f954',
}

export const INIT_CODE_HASH_MAP = {
  [ChainId.MAINNET]: '0x6e9d1a9446dcdf45071ca6c9bedfdfa09b568b1a208558039c34e9aa660bb9da',
  [ChainId.TESTNET]: '0x2867e50cb7f7c9c1c27423c1f69d0979879b0fdabd3f5d40ba8d2bb20911ecd4',
  [ChainId.PG_TEST]: '0x33f2a1982b2494603616ad93aab150dcbbaab18b7813e4ad8384dea23d1d96ef',
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'),
}
