import { TrustWalletIcon } from 'uikit'
import tokens from './tokens'
import { SerializedFarmConfig } from './newType'

const priceHelperLps: SerializedFarmConfig[] = [
  /**
   * These LPs are just used to help with price calculation for MasterChef LPs (farms.ts).
   * This list is added to the MasterChefLps and passed to fetchFarm. The calls to get contract information about the token/quoteToken in the LP are still made.
   * The absence of a PID means the masterchef contract calls are skipped for this farm.
   * Prices are then fetched for all farms (masterchef + priceHelperLps).
   * Before storing to redux, farms without a PID are filtered out.
   */
  {
    pid: null,
    lpSymbol: 'USDT-PG LP',
    lpAddresses: {
      102000: '',
      20201022: '0x11e82E2997a05c478C3348376FDb493671e2F935',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
    rewardTokenList: [],
    isHelperPrice: true
  },
]

export default priceHelperLps
