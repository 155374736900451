/* eslint-disable no-param-reassign */
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PriceApiListThunk, PriceState} from 'state/types'
import {Token, TokenConfig, TokenPriceConfig} from 'config/constants/types'
import farmConfng from 'config/constants/newFarms'
import {getCalcPriceContract} from 'utils/contractHelpers'
import {getAddress} from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import {BIG_TEN} from 'utils/bigNumber'
// import tokens from 'config/constants/tokens'

const initialState: PriceState = {
    isLoading: false,
    data: null,
}

const getTokenFromLpSymbol = (token: Token): TokenConfig => {
    // if (token.symbol.split('-').length > 1) {
    //   const tokenSymbol = token.symbol.split('-')[1] === 'BNB' ? 'WBNB' : token.symbol.split('-')[1]
    //   return tokens[tokenSymbol.toLocaleLowerCase()]
    // }
    return {
        symbol: token.symbol,
        address: token.address as any,
        decimals: token.decimals,
        projectLink: token.projectLink
    }
}
export const fetchPrice = async () => {
    const tokenList: { [x: string]: TokenPriceConfig } = {
        '0x0cF4071940782b640d0b595Cb17bDf3E90869d70': {
            symbol: "WPG",
            address: "0x0cF4071940782b640d0b595Cb17bDf3E90869d70",
            decimals: 18,
            price: ""
        }
    }
    const lpTokenList: { [x: string]: TokenPriceConfig } = {}
    const tokenPrices: PriceApiListThunk = {}
    // poolsConfig.filter(f => !f.isFinished).forEach(f => {
    //   if (f) {
    //     const stakeToken = getTokenFromLpSymbol(f.stakingToken)
    //     const earnToken = getTokenFromLpSymbol(f.earningToken)
    //     const stakeAddres = getAddress(stakeToken.address)
    //     const earnAddress = getAddress(earnToken.address)
    //     const stakePriceToken = {
    //       symbol: stakeToken.symbol,
    //       address: stakeAddres,
    //       decimals: stakeToken.decimals,
    //       price: ''
    //     }
    //     const earnPriceToken = {
    //       symbol: earnToken.symbol,
    //       address: earnAddress,
    //       decimals: earnToken.decimals,
    //       price: ''
    //     }
    //     if (stakeToken.symbol.split('-').length > 1) {
    //       lpTokenList[stakeAddres] = stakePriceToken
    //     } else {
    //       tokenList[stakeAddres] = stakePriceToken
    //     }
    //     if (earnToken.symbol.split('-').length > 1) {
    //       lpTokenList[earnAddress] = earnPriceToken
    //     } else {
    //       tokenList[earnAddress] = earnPriceToken
    //     }
    //   }
    // })

// console.info(farmConfng,'lpAddress---',farmConfng.filter(f => f.pid !== 0))
    let rewardListAddr = []
    farmConfng.forEach(f => {
        const lpAddress = getAddress(f.lpAddresses)
        const tokenAddress = f.token.address
        const quoteTokenAddress = f.quoteToken.address
        f.rewardTokenList.forEach(e => {
            rewardListAddr.push(e.address)
        })
        lpTokenList[lpAddress] = {
            symbol: f.lpSymbol,
            address: lpAddress,
            decimals: 18,
            price: ''
        },
            tokenList[tokenAddress] = {
                symbol: f.token.symbol,
                address: tokenAddress,
                decimals: 18,
                price: ''
            },
            tokenList[quoteTokenAddress] = {
                symbol: f.quoteToken.symbol,
                address: quoteTokenAddress,
                decimals: 18,
                price: ''
            }
    })

    const addresss = Object.keys(tokenList).concat(Array.from(new Set(rewardListAddr)))
    const lpAddress = Object.keys(lpTokenList)
    const calcPriceContract = getCalcPriceContract()
    try {
        const tokenPricesResult = await calcPriceContract.getDefaultTokenPrices(addresss)
        const lpTokenPricesResult = await calcPriceContract.getDefaultLpPrices(lpAddress)

        addresss.forEach((d, i) => {
            tokenPrices[d.toLocaleLowerCase()] = new BigNumber(tokenPricesResult[i].toString()).div(BIG_TEN.pow(18)).toNumber()
        })
        lpAddress.forEach((d, i) => {
            tokenPrices[d.toLocaleLowerCase()] = new BigNumber(lpTokenPricesResult[i].toString()).div(BIG_TEN.pow(18)).toNumber()
        })
        return tokenPrices
    } catch (error) {
        return null
    }
}

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiListThunk>('prices/fetch', async () => {
    const prices = await fetchPrice()
    return prices
})

export const pricesSlice = createSlice({
    name: 'prices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPrices.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiListThunk>) => {
            state.isLoading = false
            if (!state.data && action.payload) {
                state.data = action.payload
            }
        })
    },
})

export default pricesSlice.reducer
