import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { CHAIN_ID } from './networks'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const vaultPoolConfig = {
  // [VaultKey.CakeVault]: {
  //   name: <Trans>Auto CAKE</Trans>,
  //   description: <Trans>Automatic restaking</Trans>,
  //   autoCompoundFrequency: 5000,
  //   gasLimit: 500000,
  //   tokenImage: {
  //     primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
  //     secondarySrc: '/images/tokens/autorenew.svg',
  //   },
  // },
  // [VaultKey.IfoPool]: {
  //   name: 'IFO CAKE',
  //   description: <Trans>Stake CAKE to participate in IFOs</Trans>,
  //   autoCompoundFrequency: 1,
  //   gasLimit: 500000,
  //   tokenImage: {
  //     primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
  //     secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
  //   },
  // },
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      20201022: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
      102000: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.cake,
    earningToken: serializedTokens.cake,
    contractAddress: {
      20201022: '0x84e3208578eE7db397a3d584d97Fea107b15bF35',
      102000: '0x84e3208578eE7db397a3d584d97Fea107b15bF35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '10',
    deployedBlockNumber: 20300877,
    version: 3,
  },
].filter((p) => !!p.contractAddress[CHAIN_ID])

export default pools
