import {SerializedFarmConfig} from 'config/constants/newType'
import BigNumber from 'bignumber.js'
import {BIG_TEN, BIG_ZERO} from '../../utils/bigNumber'
import {fetchPublicFarmsData} from './fetchPublicFarmData'
import {fetchMasterChefData, fetchMasterChefPCoinRewardPerBlock} from './fetchMasterChefData'
import {SerializedFarm} from '../newType'
import {simpleRpcProvider} from 'utils/providers'
import {getFarmApr} from 'utils/apr'
import {fetchPrice} from 'state/prices'
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import {ChainId} from "../../sdk";

const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]): Promise<SerializedFarm[]> => {
    // const { chainId } = useActiveWeb3React()
    const farmResult = await fetchPublicFarmsData(farmsToFetch)
    const masterChefResult = await fetchMasterChefData(farmsToFetch)

    const currentBlock = await simpleRpcProvider.getBlockNumber()
    const farmRewardPerBlock = await fetchMasterChefPCoinRewardPerBlock(farmsToFetch)
    // console.info(farmRewardPerBlock, farmResult, masterChefResult, 'farmResult1')
    // const totalAllocPoint = await fetchMasterChefTotalAllocPoint()


    const prices = await fetchPrice()
    return farmsToFetch.map((farm, index) => {
        try {
            const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
                farmResult[index]
            const [info] = masterChefResult[index]

            const farmLpPrice = prices ? prices[farm.lpAddresses[20201022].toLowerCase()] : BIG_ZERO

            const totalLiquidityValue = new BigNumber(lpTokenBalanceMC.toString()).times(farmLpPrice).div(1e18)
            const farmApr = farmRewardPerBlock[index].map((e, i) => {
                const rewardTokenPrice = prices ? new BigNumber(prices[farm.rewardTokenList[i].address.toLowerCase()]) : BIG_ZERO
                return getFarmApr(new BigNumber(parseInt(e)), rewardTokenPrice, new BigNumber(totalLiquidityValue))
            })
            // console.info(farmApr,'farmApr',prices)
            //该池子总的年化利率
            const totalFarmApr = farmApr.reduce((a, b) => {
                return a += b
            }, 0)

            // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
            const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

            // Raw amount of token in the LP, including those not staked
            const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
            const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

            // Amount of quoteToken in the LP that are staked in the MC
            const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

            // Total staked in LP, in quote token value
            const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))
            const allocPoint = info ? BIG_ZERO : BIG_ZERO
            const alreadyReward = info ? BIG_ZERO : BIG_ZERO
            // const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint._hex)) : BIG_ZERO


            const isShare = false
            const isRewardPCoin = false

            // currentBlock > startBlock + totalRewardBlock // 就结束
            let isFinished = false
            let _rewardPerBlock = '0x0'
            // if (activityInfo) {
            //   const { rewardPerBlock, startBlock, totalRewardBlock } = activityInfo
            //   _rewardPerBlock = rewardPerBlock._hex
            //   isFinished = new BigNumber(currentBlock).isGreaterThan(new BigNumber(startBlock?._hex).plus(new BigNumber(totalRewardBlock?._hex)))
            // }

            let isShow = !(allocPoint.eq(0) && alreadyReward.eq(0) && (info?.isFinished ?? true))
            const res = {
                ...farm,
                token: farm.token,
                quoteToken: farm.quoteToken,
                tokenAmountTotal: tokenAmountTotal.toJSON(),
                quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
                lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
                lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
                tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
                poolWeight: '',
                // multiplier: `${allocPoint.toString()}X`,
                isFinished: isFinished,
                isShare: isShare,
                isShow: isShow,
                isRewardPCoin: isRewardPCoin,
                totalLiquidityValue: new BigNumber(totalLiquidityValue).toJSON(),
                allocPoint: allocPoint.toJSON(),
                totalFarmApr,
                farmApr,
                totalAllocPoint: '0',
                pCoinRewardPerBlock: '0',
                rewardPerBlock: _rewardPerBlock,
                rewardToken: info?.rewardToken ?? "",
                ftTokenAddress: masterChefResult[index]['ftTokenAddress']
            }
            return res
        } catch (ex) {
            console.error(ex)
        }
    })
}

export default fetchFarms
