import type {
    UnknownAsyncThunkFulfilledAction,
    UnknownAsyncThunkPendingAction,
    UnknownAsyncThunkRejectedAction,
} from '@reduxjs/toolkit/dist/matchers'
import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import stringify from 'fast-json-stable-stringify'
import farmsConfig from 'config/constants/farms'
import isArchivedPid from 'utils/farmHelpers'
import type {AppState} from 'state'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import getFarmsPrices from './getFarmsPrices'
import {
    fetchFarmUserAllowances,
    fetchFarmUserClaimableRewardPCoin,
    fetchFarmUserEarnings,
    fetchFarmUserStakedBalances,
    fetchFarmUserTokenBalances
} from './fetchFarmUser'
import {SerializedFarm, SerializedFarmsState} from '../types'
import {fetchMasterChefFarmPoolLength} from './fetchMasterChefData'
import {resetUserState} from '../global/actions'

const noAccountFarmConfig = farmsConfig.map((farm) => ({
    ...farm,
    userData: {
        allowance: '0',
        tokenBalance: '0',
        stakedBalance: '0',
        earnings: '0',
        w3: '0'
    },
}))

const initialState: SerializedFarmsState = {
    data: noAccountFarmConfig,
    loadArchivedFarmsData: false,
    userDataLoaded: false,
    loadingKeys: {},
}

export const nonArchivedFarms = farmsConfig.filter(({pid}) => !isArchivedPid(pid))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<
    [SerializedFarm[], number],
    number[],
    { state: AppState }
>(
    'farms/fetchFarmsPublicDataAsync',
    async (pids) => {
        const poolLength = await fetchMasterChefFarmPoolLength()
        const farmsToFetch = farmsConfig.filter((farmConfig) => farmConfig.pid || farmConfig.pid === 0)
        // const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
        const farmsCanFetch = farmsToFetch.filter((f) => poolLength.gt(f.pid))
        // Add price helper farms
        const farmsWithPriceHelpers = farmsCanFetch.concat(priceHelperLpsConfig)

        const farms = await fetchFarms(farmsWithPriceHelpers)
        //console.log('获取farms价格', { pids, farmsToFetch, farmsWithPriceHelpers, farms })
        const farmsWithPrices = getFarmsPrices(farms)

        // Filter out price helper LP config farms
        const farmsWithoutHelperLps = farmsWithPrices.filter((farm: SerializedFarm) => {
            return farm.pid || farm.pid === 0
        })
        return [farmsWithoutHelperLps, poolLength.toNumber()]
    },
    {
        condition: (arg, {getState}) => {
            const {farms} = getState()
            if (farms.loadingKeys[stringify({type: fetchFarmsPublicDataAsync.typePrefix, arg})]) {
                console.debug('farms action is fetching, skipping here')
                return false
            }
            return true
        },
    },
)

interface FarmUserDataResponse {
    pid: number
    allowance: string
    tokenBalance: string
    stakedBalance: string
    earnings: string
    w3: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<
    FarmUserDataResponse[],
    { account: string; pids: number[] },
    {
        state: AppState
    }
>(
    'farms/fetchFarmUserDataAsync',
    async ({account, pids}) => {
        try {
            const poolLength = await fetchMasterChefFarmPoolLength()
            const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
            const farmsCanFetch = farmsToFetch.filter((f) => poolLength.gt(f.pid))
            const userFarmAllowances = await fetchFarmUserAllowances(account, farmsCanFetch)
            const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsCanFetch)
            const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsCanFetch)
            const userFarmEarnings = await fetchFarmUserEarnings(account, farmsCanFetch)
            const userClaimableRewardPCoin = await fetchFarmUserClaimableRewardPCoin(account, farmsCanFetch)
            // const userClaimableRewardPCoin = farmsCanFetch.map(m => "0")
            var res = userFarmAllowances.map((farmAllowance, index) => {
                return {
                    pid: farmsCanFetch[index].pid,
                    allowance: userFarmAllowances[index],
                    tokenBalance: userFarmTokenBalances[index],
                    stakedBalance: userStakedBalances[index],
                    earnings: userFarmEarnings[index],
                    w3: userClaimableRewardPCoin[index],
                }
            })
            console.group('[Remote Data Transport]farm user data')
            //console.log('userData', res)
            console.groupEnd()
            return res
        } catch (error) {
            console.error(error)
        }
    },
    {
        condition: (arg, {getState}) => {
            const {farms} = getState()
            if (farms.loadingKeys[stringify({type: fetchFarmUserDataAsync.typePrefix, arg})]) {
                console.debug('farms user action is fetching, skipping here')
                return false
            }
            return true
        },
    },
)

type UnknownAsyncThunkFulfilledOrPendingAction =
    | UnknownAsyncThunkFulfilledAction
    | UnknownAsyncThunkPendingAction
    | UnknownAsyncThunkRejectedAction

const serializeLoadingKey = (
    action: UnknownAsyncThunkFulfilledOrPendingAction,
    suffix: UnknownAsyncThunkFulfilledOrPendingAction['meta']['requestStatus'],
) => {
    const type = action.type.split(`/${suffix}`)[0]
    return stringify({
        arg: action.meta.arg,
        type,
    })
}

export const farmsSlice = createSlice({
    name: 'Farms',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetUserState, (state) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            state.data = state.data.map((farm) => {
                return {
                    ...farm,
                    userData: {
                        allowance: '0',
                        tokenBalance: '0',
                        stakedBalance: '0',
                        earnings: '0',
                        w3: '0'
                    },
                }
            })
            state.userDataLoaded = false
        })
        // Update farms with live data
        builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
            const [farmPayload, poolLength] = action.payload
            state.data = state.data.map((farm) => {
                const liveFarmData = farmPayload.find((farmData) => farmData.pid === farm.pid)
                return {...farm, ...liveFarmData}
            })
            //console.log('获取价格fetchFarmsPublicDataAsync', state.data)
            state.poolLength = poolLength
        })

        // Update farms with user data
        builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
            action.payload.forEach((userDataEl) => {
                const {pid} = userDataEl
                const index = state.data.findIndex((farm) => farm.pid === pid)
                state.data[index] = {...state.data[index], userData: userDataEl}
            })
            state.userDataLoaded = true
        })

        builder.addMatcher(isAnyOf(fetchFarmUserDataAsync.pending, fetchFarmsPublicDataAsync.pending), (state, action) => {
            state.loadingKeys[serializeLoadingKey(action, 'pending')] = true
        })
        builder.addMatcher(
            isAnyOf(fetchFarmUserDataAsync.fulfilled, fetchFarmsPublicDataAsync.fulfilled),
            (state, action) => {
                state.loadingKeys[serializeLoadingKey(action, 'fulfilled')] = false
            },
        )
        builder.addMatcher(
            isAnyOf(fetchFarmsPublicDataAsync.rejected, fetchFarmUserDataAsync.rejected),
            (state, action) => {
                state.loadingKeys[serializeLoadingKey(action, 'rejected')] = false
            },
        )
    },
})

export default farmsSlice.reducer
