import {SerializedFarmConfig} from './newType'
import {serializeTokens} from "./tokens";

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
    {
        pid:0,
        lpSymbol: 'PG/USDT',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0x025FD0fbEA749E0ac2F032A6F53b6d70bD1adb58',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.usdt,
        rewardTokenList: [
            {
                ...serializedTokens.pns,
                multiplier: '600X',
                isHidden:true
            },
            {
                ...serializedTokens.w3,
                multiplier: '600X',
            },
            {
                ...serializedTokens.pns,
                multiplier: '600X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '600X'
            },
            {
                ...serializedTokens.w3b,
                multiplier: '600X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },
    {
        pid:1,
        lpSymbol: 'PG/OSKDAO',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0x91bbFbA8ad863AC66b8eA74429a183a31944d096',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.oskdao,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '300X',
            },
            {
                ...serializedTokens.pns,
                multiplier: '300X'
            },
            {
                ...serializedTokens.oskdao,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X',
                isHidden:true
            },
            {
                ...serializedTokens.w3b,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },
    {
        pid:2,
        lpSymbol: 'PG/W3',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0x0225ebD3EB20BFa289D51e25B476EA7452F3eAd3',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.w3,
        rewardTokenList: [
            {
                ...serializedTokens.pns,
                multiplier: '100X'
            },
            {
                ...serializedTokens.w3,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3b,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },
    {
        pid:3,
        lpSymbol: 'PG/W3W',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0xD3Af36CB864a02e14B962616c42963d9E42d1c36',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.w3w,
        rewardTokenList: [
            {
                ...serializedTokens.pns,
                multiplier: '100X'
            },
            {
                ...serializedTokens.w3,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3b,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },
    {
        pid:4,
        lpSymbol: 'PG/W3B',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0xD26D63b39d7706B7dD3D119ba34eEA53b205E545',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.w3b,
        rewardTokenList: [
            {
                ...serializedTokens.pns,
                multiplier: '100X'
            },
            {
                ...serializedTokens.w3,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3b,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },
    {
        pid:5,
        lpSymbol: 'PG/W3K',
        isHelperPrice: false,
        multiplier:'1000X',
        lpAddresses: {
            102000: '',
            20201022: '0x17634A4A66f48a236b0fc4ffb3AB73Dc97840A7d',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.w3k,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.pns,
                multiplier: '100X'
            },
            {
                ...serializedTokens.w3b,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3w,
                multiplier: '1000X'
            },
            {
                ...serializedTokens.w3k,
                multiplier: '1000X'
            },
        ],
    },



    {
        pid:6,
        lpSymbol: 'PG/GSS',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0xf69640ae5bfbaaf92a77302d8a4e8f2a6c7f5dd0',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.gss,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            },
            {
                ...serializedTokens.daausdt,
                multiplier: '0X'
            },
            {
                ...serializedTokens.gsd,
                multiplier: '0X'
            },
            {
                ...serializedTokens.g50,
                multiplier: '0X'
            },
            {
                ...serializedTokens.mn,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:7,
        lpSymbol: 'PG/FOG',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0x5F34Ae74E11DE396205C888b46999AaA5Bd0805f',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.fog,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            },
            {
                ...serializedTokens.fog,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:8,
        lpSymbol: 'PG/PCT',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0xc2e41aA66d5DFaAC5883E2482bC2eFa6d0565CfC',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.pct,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:9,
        lpSymbol: 'PG/IWO',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0xE45355Ef690d8cE4036024e643D242C175fE5218',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.iwo,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            },
            {
                ...serializedTokens.iwo,
                multiplier: '0X'
            },
            {
                ...serializedTokens.wpg,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:11,
        lpSymbol: 'PG/PBK',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0x896a1533233dDCd6302207B36D357A42c74d53d5',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.pbk,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:12,
        lpSymbol: 'PG/4MW',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0xC2700Dce2D58B49C2bE307F542C6242c8e19022C',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.mw,
        rewardTokenList: [
            {
                ...serializedTokens.mw,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:13,
        lpSymbol: 'PG/WMN',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0x236DEC91B069624Ffd9eA6176C952e629415657C',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.wmn,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            },
            {
                ...serializedTokens.wzz,
                multiplier: '0X'
            },
            {
                ...serializedTokens.tqloong,
                multiplier: '0X'
            },
            {
                ...serializedTokens.wmn,
                multiplier: '0X'
            },
            {
                ...serializedTokens.tqloongnew,
                multiplier: '0X'
            }
        ],
    },
    {
        pid:14,
        lpSymbol: 'PG/Dora',
        isHelperPrice: false,
        multiplier:'0X',
        lpAddresses: {
            102000: '',
            20201022: '0x3eE3DE6F2Dff898C6Ed8e1E2EfEC1E6Ed6D0b75C',
        },
        token: serializedTokens.wbnb,
        quoteToken: serializedTokens.dora,
        rewardTokenList: [
            {
                ...serializedTokens.w3,
                multiplier: '0X'
            },
            {
                ...serializedTokens.wbnb,
                multiplier: '0X'
            }
        ],
    },
]

export default farms
