import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from 'uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .datePicker{
    background-color: #E7BB4B !important;
  }

  .is-selected-day{
    background-color: #E7BB4B !important;
  }

  .is-today{box-shadow:inset 0px 0px 0px 2px #E7BB4B !important}

  .time--input{
    color: #000000 !important;
    background: #ffffff !important;
  }

  .input-picker--input{
    border-radius: 16px !important;
    margin-left: auto !important;
    color:#ffffff !important;
    background-color: #484A4D !important;
    height: 40px !important;
    border-width: 0 !important;
  }

  .calender-modal{
    z-index: 99999 !important;
  }
`

export default GlobalStyle
