import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'BUSD/WBNB LP',
  //   isHelperPrice: true,
  //   lpAddresses: {
  //     97: '0x2F384dC9220435891AeeE98DC18af4aEFe284Dcc',
  //     56: '0x368127F770a776d1F0a96dae914e2df6B5c231EA',
  //     102000: '0x368127F770a776d1F0a96dae914e2df6B5c231EA',
  //   },
  //   token: serializedTokens.busd,
  //   quoteToken: serializedTokens.wbnb,
  //   rewardTokenList: ''
  // },
  {
    pid: 1,
    lpSymbol: 'PG/USDT LP',
    isHelperPrice: true,
    lpAddresses: {
      20201022: '0xDE45FD3938992C1f4E5f2513Bf47c2fC61C7f47C',
      102000: '0xDE45FD3938992C1f4E5f2513Bf47c2fC61C7f47C',
    },
    token: serializedTokens.pg,
    quoteToken: serializedTokens.usdt,
    rewardTokenList: ''
  }
].filter((f) => !!f.lpAddresses[CHAIN_ID])

export default farms
